<template>
  <div class="stores-business-lines">
    <div class="bread-crumbs">
      <div class="bread-crumb-item clickable" @click="getBusinessLines">
        Business Lines
      </div>
      <div class="bread-crumb-item separator">
        <i class="fa fa-chevron-right"></i>
      </div>
      <div class="bread-crumb-item">{{ this.lineName }}</div>
      <div class="bread-crumb-item separator">
        <i class="fa fa-chevron-right"></i>
      </div>
      <div class="bread-crumb-item">Stores</div>
    </div>
    <ul class="table-header table-row">
      <li class="name head-cell">Name</li>
      <li class="type head-cell">Type</li>
      <li class="description head-cell">Description</li>
      <li class="image head-cell">Image</li>
      <li class="vendors head-cell">Products</li>
      <li class="action head-cell">Action</li>
    </ul>
    <ul class="table-row body-row" v-for="line in stores" :key="line.id">
      <li class="name body-cell">{{ line.name }}</li>
      <li class="type body-cell">{{ line.type }}</li>
      <li class="description body-cell">
        {{ line.description }}
      </li>
      <li class="image body-cell">
        <StackedImages :images="[line.image]" type="product" />
      </li>
      <li class="vendors body-cell">{{ line.products }}</li>
      <li class="action body-cell">
        <div class="action-box">
          <div class="single-row">
            <span class="edit-btn">Edit</span>
            <span class="archive-btn">Archive</span>
          </div>
          <span class="cats-btn"> View Products </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import StackedImages from "@/view/layout/dashboard_components/StackedImages";
export default {
  components: {
    StackedImages
  },
  props: {
    lineId: String,
    lineName: String
  },
  data() {
    return {
      stores: []
    };
  },

  methods: {
    getData: function() {
      const tk = JwtService.getToken();
      ApiService.post("/store/showStoresbyBusinessLines", {
        token: `${tk}`,
        storeType: `${this.lineId}`
      }).then(res => {
        this.extractTable(res.data.data);
      });
    },
    extractTable: function(arr) {
      // name, type, description, images, categories, action
      let array = [];
      arr.map(item => {
        let s = {};
        s.id = item._id;
        if (item.name) {
          s.name = item.name;
        } else {
          s.name = item.storeName;
        }
        s.description = item.description;
        s.type = item.storeType;
        s.image = item.storeLogo;
        s.products = item.products.length;
        array.push(s);
      });
      this.stores = array;
    },
    getBusinessLines: function() {
      this.$emit("hideStoresBusinessLine");
    }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style lang="scss" scoped>
$red: #dc1828;
.stores-business-lines {
  .bread-crumbs {
    display: flex;
    .bread-crumb-item {
      font-size: 22px;
      margin: 0 5px;
      color: #3f4254;
      &.clickable {
        cursor: pointer;
      }
      &.separator {
        i {
          font-size: 22px;
          color: #3f4254;
        }
      }
    }
  }
  ul.table-row {
    margin: 0;
    list-style: none;
    display: flex;
    background: #fff;
    width: 100%;
    padding: 5px;
    position: relative;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      &.head-cell {
        padding: 10px;
        color: $red;
        font-weight: 600;
      }
      &.body-cell {
        text-align: center;
      }
      &.name {
        width: 15%;
        justify-content: flex-start;
        &.name {
          padding-left: 25px;
        }
      }
      &.type {
        width: 15%;
      }
      &.description {
        width: 20%;
      }
      &.image {
        width: 15%;
      }
      &.vendors {
        width: 15%;
      }
      &.action {
        width: 20%;
        .action-box {
          width: 100%;
          display: flex;
          flex-direction: column;
          .single-row {
            display: grid;
            grid-template-columns: auto auto;
          }
          span {
            display: inline-flex;
            margin: 5px;
            cursor: pointer;
            padding: 7px 20px;
            border-radius: 15px;
            justify-content: center;
            color: #fff;
          }
          .edit-btn {
            background: #59fb71;
          }
          .archive-btn {
            background: #f4b144;
          }
          .cats-btn {
            background: #50a6fa;
          }
        }
      }
    }
  }
  ul.table-header {
    box-shadow: 0px 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
    z-index: 3;
  }
  ul.body-row {
    margin-bottom: 2px;
  }
}
</style>
