<template>
  <div class="business-lines-table">
    <ul class="table-header table-row">
      <li class="name head-cell">Name</li>
      <li class="type head-cell">Type</li>
      <li class="description head-cell">Description</li>
      <li class="image head-cell">Image</li>
      <li class="vendors head-cell">Vendors</li>
      <li class="action head-cell">Action</li>
    </ul>

    <ul class="table-row body-row">
      <li class="name body-cell">Food</li>
      <li class="type body-cell">Vendor Based</li>
      <li class="description body-cell">
        Purchase Groceries from a shop near you.
      </li>
      <li class="image body-cell">Image</li>
      <li class="vendors body-cell">9</li>
      <li class="action body-cell">
        <div class="action-box">
          <span class="unarchive-btn">Unarchive</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$red: #dc1828;
.business-lines-table {
  margin-top: 15px;

  ul.table-row {
    margin: 0;
    list-style: none;
    display: flex;
    background: #fff;
    width: 100%;
    padding: 5px;
    position: relative;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      &.head-cell {
        padding: 10px;
        color: $red;
        font-weight: 600;
      }
      &.body-cell {
        font-weight: 600;
        text-align: center;
      }
      &.name {
        width: 15%;
        justify-content: flex-start;
        &.name {
          padding-left: 25px;
        }
      }
      &.type {
        width: 15%;
      }
      &.description {
        width: 20%;
      }
      &.image {
        width: 15%;
      }
      &.vendors {
        width: 15%;
      }
      &.action {
        width: 20%;
        .action-box {
          width: 100%;
          span {
            display: inline-flex;
            margin: 5px;
            cursor: pointer;
            padding: 7px 20px;
          }
          .unarchive-btn {
            border-radius: 10px;
            background: #87fa9e;
            background: #f8b040;
          }
          .view-btn {
            border-radius: 10px;
            background: #7bc0fe;
          }
        }
      }
    }
  }
  ul.table-header {
    box-shadow: 0px 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
    z-index: 3;
  }
  ul.body-row {
    margin-bottom: 2px;
  }
}
</style>
