<template>
  <div class="business-lines">
    <div class="row">
      <div class="top-row w-100">
        <div
          class="create-new-btn shadow-sm"
          v-if="!activeTab"
          @click="openNewModal"
        >
          Create New
        </div>
        <div class="menu-pill-container shadow-sm">
          <div
            class="menu-pill"
            v-bind:class="activeTab === 0 ? 'active' : ''"
            @click="changeTab(0)"
          >
            Business Lines
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 1 ? 'active' : ''"
            @click="changeTab(1)"
          >
            Archive
          </div>
        </div>
      </div>
    </div>
    <b-tabs content-class="mt-3" align="center" v-model="activeTab">
      <b-tab active>
        <BusinessLinesTable
          v-if="showBusinessLinesTable"
          ref="tableRef"
          @viewStoreByLine="viewStoreByLine"
        />
        <StoresBusinessLine
          v-if="showStoresBusinessLine"
          :lineId="lineId"
          :lineName="lineName"
          @hideStoresBusinessLine="hideStoresBusinessLine"
        />
      </b-tab>
      <b-tab>
        <BusinessLinesArchive />
      </b-tab>
    </b-tabs>
    <NewBusinessLine ref="BusinessModalRef" @updateLines="updateLines" />
  </div>
</template>

<script>
import BusinessLinesTable from "@/view/pages/business-lines/BusinessLinesTable";
import BusinessLinesArchive from "@/view/pages/business-lines/BusinessLinesArchive";
import NewBusinessLine from "@/view/pages/business-lines/NewBusinessLine";
import StoresBusinessLine from "@/view/pages/business-lines/StoresBusinessLine";

export default {
  components: {
    BusinessLinesTable,
    BusinessLinesArchive,
    NewBusinessLine,
    StoresBusinessLine
  },

  data() {
    return {
      activeTab: 0,
      showBusinessLinesTable: 1,
      showStoresBusinessLine: 0,
      lineId: null,
      lineName: null
    };
  },
  methods: {
    updateLines: function() {
      this.$refs.tableRef.updateLines();
    },
    changeTab: function(index) {
      this.activeTab = index;
    },
    openNewModal: function() {
      this.$refs.BusinessModalRef.showModal();
    },
    viewStoreByLine: function(obj) {
      this.lineId = obj.id;
      this.lineName = obj.name;
    },
    hideStoresBusinessLine: function() {
      this.lineId = null;
      this.lineName = null;
    }
  },
  watch: {
    lineId: function(val) {
      if (val !== null) {
        this.showBusinessLinesTable = 0;
        this.showStoresBusinessLine = 1;
      } else {
        this.showBusinessLinesTable = !this.showBusinessLinesTable;
        this.showStoresBusinessLine = !this.showStoresBusinessLine;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.business-lines {
  .top-row {
    z-index: 96;
    display: flex;
    position: relative;
    justify-content: center;
    .create-new-btn {
      position: absolute;
      right: 15px;
      top: 0;
      background: #fff;
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 10px;
      font-weight: 700;
    }
    .menu-pill-container {
      border-radius: 50px;
      overflow: hidden;

      .menu-pill {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 15px;
        width: 150px;
        &.active {
          background: #dc1828;
          color: #fff;
        }
        color: #000;
      }
    }
  }
  ::v-deep .nav-tabs {
    display: none;
  }
}
</style>
